<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-card
        v-if="blogList"
        no-body
        class="mb-0"
    >
      <div class="ecommerce-searchbar p-1">
        <b-row>
          <b-col cols="12" md="4">
            <b-input-group class="input-group-merge">
              <debouncer :placeHolder="`جستجوی بلاگ ها`" @setValue="getsearchValue"></debouncer>


            </b-input-group>
          </b-col>

          <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-2">دسته بندی</label>
            <v-select
                v-model="categoryId"
                :options="blogCategories"
                label="name"
                :reduce="name => name.blogCategoryId"
                :clearable="false"
                class="per-page-selector w-75"
            />
          </b-col>
          <b-col cols="12" lg="2">
            <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
          </b-col>
        </b-row>
      </div>


      <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="blogList"
          responsive
          :sort-by.sync="sortBy"
          :fields="myTableColumns"
          primary-key="id"
          show-empty
          bordered
          striped
          empty-text="موردی موجود نیست!"
      >

        <template #cell(blogId)="data">
          <a :href="returnUrl + `magazine/${data.item.blogId}/${data.item.seoTitle}/`" target="_blank">
            <b-avatar size="100" square :src="baseURL+data.item.fileData"/>
            <span class="text-muted ml-2">@{{ data.item.blogId }}</span>
          </a>
        </template>

        <template #cell(operation)="data">
          <div class="d-flex align-items-center justify-content-between  ">

            <b-link :to="{ name: 'pages-blog-edit', params: { id: data.item.blogId } }">
              <feather-icon class="text-primary" icon="EditIcon" size="20"/>
            </b-link>
            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-delete
                 @click="showModal(data.item.blogId)"
            >
              <feather-icon icon="TrashIcon" size="20" class="text-danger"/>
            </div>

            <div class="cursor-pointer d-flex flex-row"
                 @click="$router.push({ name: 'pages-blog-commentsById', params: { id: data.item.blogId }})"
            >
              <feather-icon class="text-success" icon="MessageSquareIcon" size="20"/>
            </div>
          </div>
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-end "
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                :per-page="10"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon style="transform: rotate(180deg)"
                              icon="ChevronLeftIcon"
                              size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteBlog(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BFormRadio,
  BOverlay,
  BModal, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {kFormatter} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import {BlogCategoryGetAllRequest} from "@/libs/Api/Blog";
import {BlogDeleteRequest} from "@/libs/Api/Blog";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BlogGetAllRequest} from "@/libs/Api/Blog";
import Helper from "@/libs/Helper";
import {getUserData} from "@/auth/utils";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";
import Debouncer from "@/views/components/debouncer.vue";

export default {
  title: "لیست بلاگ ها",
  components: {
    Debouncer,
    ClearSortsBtn,
    BRow,
    BCol,
    BTable,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BFormRadio,
    BOverlay,
    BModal,
    vSelect,
    ContentWithSidebar,
  },
  data() {
    return {
      baseURL: Helper.baseUrl,
      returnUrl: Helper.returnUrl,
      showOverlay: false,
      searchQuery: '',
      blogList: null,
      blogSidebar: {},
      sortBy: '',
      deleteItem: null,
      blogCategories: [
        {
          name: 'همه',
          blogCategoryId: ''
        }
      ],
      currentPage: 1,
      totalCount: null,
      categoryId: '',
      defaultGetProductData: {
        categoryId: '',
        searchByTitle: '',
        pageNumber: 1,
        count: 8,
        tagId: 0,
      },
      myTableColumns: [
        {
          key: 'blogId',
          label: 'شناسه و تصویر شاخص',
          sortable: true
        },
        {
          key: 'title',
          label: 'عنوان',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },

      ],
    }
  },
  async created() {
    await this.getBlogs(this.defaultGetProductData.categoryId, this.defaultGetProductData.searchByTitle, this.defaultGetProductData.pageNumber);
    await this.getBlogCategories();
  },
  methods: {
    getsearchValue(val) {
      this.searchQuery = val
      this.getBlogs(this.defaultGetProductData.categoryId, this.searchQuery, this.defaultGetProductData.pageNumber);
    },
    async getBlogs(blogCategoryId, search, pageNumber) {
      let _this = this;
      _this.showOverlay = true;
      let getBlogData = {
        blogCategoryId: blogCategoryId,
        searchByTitle: search,
        pageNumber: pageNumber,
        count: 10,
        tagId: 0,
        vendorUserId: getUserData().userId
      }

      let blogGetAllRequest = new BlogGetAllRequest(_this);
      blogGetAllRequest.setParams(getBlogData)
      await blogGetAllRequest.fetch(function (content) {
        _this.blogList = content.blogs;
        _this.totalCount = content.blogsCount;
        _this.showOverlay = false;
      }, function (content) {
        _this.showOverlay = false;
        console.log(content)
      });
    },
    async getBlogCategories() {
      let _this = this;
      let data = {
        pageNumber: 0,
        count: 0
      }

      let blogCategoryGetAllRequest = new BlogCategoryGetAllRequest(_this);
      await blogCategoryGetAllRequest.fetch(function (content) {
        content.blogCategories.forEach(el => {
          _this.blogCategories.push(el);
        })
      }, function (content) {
        console.log(content)
      });
    },
    async deleteBlog(param) {
      let _this = this;
      _this.showOverlay = true;

      let blogDeleteRequest = new BlogDeleteRequest(_this);
      blogDeleteRequest.setId(param);
      await blogDeleteRequest.fetch(function (content) {
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `عملیات موفق`,
                icon: 'CheckIcon',
                variant: 'success',
                text: `بلاگ حذف شد.`,
              },
            })
            window.location.reload()
            _this.showOverlay = false;
          },
          function (error) {
            _this.showOverlay = false;
            console.log(error)
          })
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch: {
    currentPage: function (nv, ov) {
      this.getBlogs(this.defaultGetProductData.categoryId, this.defaultGetProductData.searchByTitle, nv);
    },

    categoryId: function (nv, ov) {
      this.getBlogs(nv, this.defaultGetProductData.searchByTitle, this.defaultGetProductData.pageNumber);
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';

.item-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
